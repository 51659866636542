import React from 'react';
import CustomHero from '../components/CustomHero';
import DefaultLayout from '../layouts/DefaultLayout';
import CTAGrid from '../components/CTAGrid';
import styled from 'styled-components';
import { respondTo } from '../util/breakpoints';
import { jobCodes } from '../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) | Official HCP Site`,
    keywords: `keytruda lenvima website, health care professionals`,
    description: `Health care professionals may find information about KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudalenvimahcp.com/","@type":"ApprovedIndication","name":"KEYTRUDA® + LENVIMA®","alternateName":"pembrolizumab + lenvatinib","description":"KEYTRUDA, in combination with LENVIMA, is indicated for the first-line treatment of adult patients with advanced renal cell carcinoma (RCC)."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudalenvimahcp.com/","@type":"ApprovedIndication","name":"KEYTRUDA® + LENVIMA®","alternateName":"pembrolizumab + lenvatinib","description":"KEYTRUDA, in combination with LENVIMA, is indicated for the treatment of patients with advanced endometrial carcinoma that is mismatch repair proficient (pMMR) as determined by an FDA-approved test or not microsatellite instability-high (MSI-H), who have disease progression following prior systemic therapy in any setting and are not candidates for curative surgery or radiation."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

const jobCode = jobCodes[0].jobCode;

const CTAGridContainer = styled.div`
    padding: 30px 20px;
    ${respondTo.md`'
        padding: 30px 0;
    `}
`;

const GridLinks = [
    {
        title: 'MECHANISMS OF ACTION',
        buttonText: 'Learn More',
        buttonTo: '/mechanisms-of-action',
        body: 'Discover how this treatment combination is thought to work'
    },
    {
        title: 'DOSING',
        buttonText: 'Learn More',
        buttonTo: '/dosing',
        body: 'Learn about dosing and administration'
    },
    {
        title: 'PATIENT SUPPORT',
        buttonText: 'Learn More',
        buttonTo: '/patient-support',
        body: 'Find information and support resources for patients'
    },
];

const Index = ({location}) => {
    return (
        <DefaultLayout location={ location } pageMeta={pageMeta}  jobCode={jobCode}>
            <CustomHero>
                <CTAGridContainer>
                    <CTAGrid linkObj={GridLinks}/>
                </CTAGridContainer>
            </CustomHero>
        </DefaultLayout>
    )
};

export default Index;
